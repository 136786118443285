import {
  Badge,
  Box,
  Button,
  Card,
  Cell,
  Dropdown,
  EmptyState,
  FormField,
  Input,
  Layout,
  Loader,
  MarketingLayout,
  Notification,
  Page,
  SectionHelper,
  Table,
  TableActionCell,
  TextButton,
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";

import "./App.css";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Exclusive Savings! and Everything in plus",
  "Offer different discounts by plans",
  "Setup conditions to apply discounts",
];

const INTERVAL = 4000;

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const [screen, setScreen] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [settings, setSettings] = React.useState({
    discounts: [],
  });
  const [plans, setPlans] = React.useState([] as any);
  const [index, setIndex] = React.useState<number>(0);

  const instance = new URLSearchParams(window.location.search).get("instance");

  const [currentDiscount, setCurrentDiscount] = React.useState({
    planId: "",
    discount: {
      type: "percentOff",
      value: 0,
    },
  } as any);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  const instanceId = React.useMemo(() => {
    return (instanceData as any)?.instance?.instanceId;
  }, [instanceData]);

  
  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    getPlans();
    // setStatus();
    // // if status is null/undefined, check again in every 5 seconds
    // const checker = setInterval(() => {
    //   if (!citationStatus) {
    //     setStatus();
    //   } else {
    //     clearInterval(checker);
    //   }
    // }, 5000)
    // const url = new URLSearchParams(window.location.search).get('siteUrl');
    // if (url) {
    //   setSiteUrl(url);
    // }
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/plans-discounts/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
        if (!data.instance.permissions.includes("PRICING_PLANS.READ_ORDERS")) {
          window.open(
            `https://go.certifiedcode.global/get/dfdce691-6f6e-457e-96a4-b4e55a4c1e99/${data.site.siteId}`,
            "_blank"
          );
        }
      })
      .catch((error) => {
        console.group("App Instance:");
        console.error(error);
        console.groupEnd();
      });
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.discounts) {
          setSettings(data);
        }
      })
      .catch(() => {});
  }

  function getPlans() {
    fetch(BASE_URL + "/plans", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPlans(data);
      })
      .catch(() => {});
  }

  function saveSettings(data: any) {
    setIsSaving(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
        setIsSaving(false);
      })
      .catch((error) => {
        console.group("App settings");
        console.error(error);
        console.groupEnd();
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=dfdce691-6f6e-457e-96a4-b4e55a4c1e99&redirectUrl=https://certifiedcode.wixsite.com/plans-discounts/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const DiscountsTable = () => {
    const renderRowDetails = (row: any) => {
      if (row.expandable) {
        return <Box height="120px" />;
      }
    };

    const columns = [
      {
        title: "Plan",
        render: (row: any) =>
          plans.find((v: any) => v.id === row.planId)?.name || row.planId,
      },
      {
        title: "Discount",
        render: (row: any) =>
          `${row.discount.type === "percentOff" ? "" : "$"}${
            row.discount.value
          }${row.discount.type === "percentOff" ? "%" : ""} off`,
      },
      {
        title: "Status",
        render: (row: any) => (
          <Badge
            skin={
              plans.find((v: any) => v.id === row.planId)
                ? "success"
                : "neutralLight"
            }
          >
            {plans.find((v: any) => v.id === row.planId)
              ? "ACTIVE"
              : "INACTIVE"}
          </Badge>
        ),
      },
      {
        title: "",
        render: (row: any) => (
          <TableActionCell
            size="small"
            primaryAction={{
              text: "Delete Discount",
              onClick: () => {
                const discounts = settings.discounts || [];
                const index = discounts.findIndex(
                  (v: any) =>
                    v.planId === row.planId &&
                    v.discount.type === row.discount.type &&
                    v.discount.value === row.discount.value
                );
                discounts.splice(index, 1);
                saveSettings({
                  ...settings,
                  discounts,
                });
              },
            }}
          />
        ),
      },
    ];

    return (
      <Box gap="SP2" direction="vertical">
        <Table
          rowDetails={(row: any) => renderRowDetails(row)}
          data={settings.discounts}
          columns={columns}
        >
          <Table.Content />
        </Table>
      </Box>
    );
  };

  const upgradeRoute = `https://www.wix.com/apps/upgrade/${"dfdce691-6f6e-457e-96a4-b4e55a4c1e99"}?appInstanceId=${instanceId}`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>{`Upgrade to unlock "${CONTENT[index]}"`}</Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeRoute, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          className="close-button"
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={screen === "" ? "Manage Discounts" : "Add Discount"}
          showBackButton={screen !== ""}
          onBackClicked={() => {
            setScreen("");
          }}
          actionsBar={
            <Box direction="horizontal" gap="SP2">
              <Button
                skin="dark"
                onClick={() => {
                  window.Intercom("showNewMessage");
                }}
              >
                Contact Support
              </Button>
              <Button
                skin="premium"
                disabled={isUpgraded}
                prefixIcon={<Icons.PremiumFilled />}
                onClick={() => {
                  window.open(upgradeRoute);
                }}
              >
                {isUpgraded ? "Manage Plan" : "Upgrade to Premium"}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          {screen === "" ? (
            <Layout>
              <Cell span={12}>
                <SectionHelper>
                  We are investigating an issue with the discounts not
                  displaying immediately (which requires refreshing the page or
                  go to checkout step). We are working on a fix and will update
                  you as soon as possible. (Last updated: Jan 26, 2024)
                </SectionHelper>
              </Cell>
              <Cell span={12}>
                <Card>
                  <Card.Header
                    title="Give discounts to plan holders"
                    suffix={
                      <Button
                        size="small"
                        prefixIcon={<Icons.AddSmall />}
                        onClick={() => {
                          setScreen("add");
                        }}
                      >
                        Add Discount
                      </Button>
                    }
                  />
                  {settings?.discounts?.length > 0 ? (
                    DiscountsTable()
                  ) : (
                    <Card.Content size="medium">
                      <EmptyState
                        theme="section"
                        title="Add your first discount"
                        subtitle="Create a discount for your plan holders"
                      >
                        <TextButton
                          prefixIcon={<Icons.Add />}
                          onClick={() => {
                            setScreen("add");
                          }}
                        >
                          Add Discount
                        </TextButton>
                      </EmptyState>
                    </Card.Content>
                  )}
                </Card>
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    title="Discover the best way to offer discounts"
                    description="Learn why Auto Savings for Plans Holders is the best solution to offer incentives to your plan holders."
                    size="tiny"
                    actions={
                      <Button
                        size="small"
                        skin={"standard"}
                        onClick={() => {
                          window.Intercom("showArticle", 8826041);
                        }}
                      >
                        Learn more
                      </Button>
                    }
                  ></MarketingLayout>
                </Card>
              </Cell>
            </Layout>
          ) : null}
          {screen === "add" ? (
            <Card>
              <Card.Header
                title="Discount Info"
                subtitle="Start by choosing a plan"
              />
              <Card.Divider />
              <Card.Content>
                <Layout>
                  <Cell span={6}>
                    <FormField label="Plan" required>
                      <Dropdown
                        statusMessage={
                          plans.filter(
                            // check if plan already has a discount
                            (v: any) => {
                              return !settings?.discounts?.find(
                                (d: any) => d.planId === v.id
                              );
                            }
                          ).length === 0
                            ? "All plans have a discount. Remove a discount to add a new one."
                            : undefined
                        }
                        status={
                          plans.filter(
                            // check if plan already has a discount
                            (v: any) => {
                              return !settings.discounts.find(
                                (d: any) => d.planId === v.id
                              );
                            }
                          ).length === 0
                            ? "error"
                            : undefined
                        }
                        options={plans
                          .filter(
                            // check if plan already has a discount
                            (v: any) => {
                              return !settings.discounts.find(
                                (d: any) => d.planId === v.id
                              );
                            }
                          )
                          .map((v: any) => {
                            return {
                              id: v.id,
                              value: v.name,
                            };
                          })}
                        onSelect={(option) => {
                          setCurrentDiscount({
                            ...currentDiscount,
                            planId: String(option.id),
                          });
                        }}
                      ></Dropdown>
                    </FormField>
                  </Cell>
                  <Cell span={12}>
                    <Card.Divider />
                  </Cell>
                  <Cell span={8}>
                    <Box direction="vertical" gap="SP2">
                      <FormField label="What is the discount?">
                        <Layout>
                          <Cell span={4}>
                            <Dropdown
                              options={[
                                { id: "percentOff", value: "Percentage off" },
                                { id: "amountOff", value: "Amount off" },
                              ]}
                              selectedId={currentDiscount.discount.type}
                              onSelect={(option) => {
                                setCurrentDiscount({
                                  ...currentDiscount,
                                  discount: {
                                    ...currentDiscount.discount,
                                    type: String(option.id),
                                  },
                                });
                              }}
                            ></Dropdown>
                          </Cell>
                          <Cell span={3}>
                            <Input
                              placeholder="0"
                              status={
                                currentDiscount.discount.value <= 0 ||
                                (currentDiscount.discount.type ===
                                  "percentOff" &&
                                  currentDiscount.discount.value > 100)
                                  ? "error"
                                  : undefined
                              }
                              statusMessage={
                                currentDiscount.discount.value <= 0
                                  ? "Discount must be greater than 0"
                                  : currentDiscount.discount.type ===
                                      "percentOff" &&
                                    currentDiscount.discount.value > 100
                                  ? "Percentage discount must be between 0 and 100"
                                  : undefined
                              }
                              suffix={
                                <Input.Affix>
                                  {currentDiscount.discount.type ===
                                  "percentOff"
                                    ? "%"
                                    : "$"}
                                </Input.Affix>
                              }
                              value={currentDiscount.discount.value}
                              onChange={(e) => {
                                let value = Number(e.currentTarget.value);
                                if (
                                  currentDiscount.discount.type === "percentOff"
                                ) {
                                  value = Math.min(Math.max(value, 0), 100); // Ensure value is between 0 and 100
                                }
                                setCurrentDiscount({
                                  ...currentDiscount,
                                  discount: {
                                    ...currentDiscount.discount,
                                    value: value,
                                  },
                                });
                              }}
                            />
                          </Cell>
                        </Layout>
                      </FormField>
                      <Button
                        disabled={
                          currentDiscount.discount.value <= 0 ||
                          currentDiscount.planId === "" ||
                          currentDiscount.discount.type === "" ||
                          isSaving ||
                          (currentDiscount.discount.value > 100 &&
                            currentDiscount.discount.type === "percentOff")
                        }
                        prefixIcon={<Icons.Add />}
                        onClick={() => {
                          // add current discount to settings
                          if (
                            currentDiscount.discount.value > 0 &&
                            currentDiscount.planId !== "" &&
                            currentDiscount.discount.type !== ""
                          ) {
                            const discounts = settings.discounts || [];
                            // @ts-ignore
                            discounts.push(currentDiscount);
                            saveSettings({
                              ...settings,
                              discounts,
                            });
                            setScreen("");
                            // reset current discount
                            setCurrentDiscount({
                              planId: "",
                              discount: {
                                type: "percentOff",
                                value: 0,
                              },
                            });
                          }
                        }}
                      >
                        Set Live
                      </Button>
                    </Box>
                  </Cell>
                </Layout>
              </Card.Content>
            </Card>
          ) : null}
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
